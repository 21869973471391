import { useFormDataAPI } from "@paykassma/pay-kit/lib/elements/PayKitForm";
import { StandardResponse } from "api/types";
import { Requisite } from "api/walletTypesGroup";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { PayKitForm, useTranslation } from "pay-kit";
import { useContext, useEffect, useState } from "react";
import { WalletTypes } from "utils/enums";
import { ModalProps } from "utils/types";

import { AccountType, ParserType } from "../ChangeWalletModal/utils/enums";
import { getWalletParserTypes } from "../ChangeWalletModal/utils/helpers";
import useGetProxies from "./hooks/useGetProxies";
import useGetProxySettings from "./hooks/useGetProxySettings";
import { useWalletData } from "./hooks/useWalletData";

export const ChangeWalletModal = ({
	closeModal,
	onSubmit,
	onSuccess,
	initForm,
	loading,
	isCreating = false,
	externalErrors,
	clearExternalErrors,
	requisites,
	fetchRequisites,
	created_at,
	adminPanelType,
}: CreateWalletModalProps) => {
	const { walletTypes } = useContext(WalletTypesContext);
	const getProxySettingsAPI = useGetProxySettings();
	const getProxiesAPI = useGetProxies();
	const { t } = useTranslation();
	const [showRequisites, setShowRequisites] = useState(false);

	const handleFormChange: FormChangeHandlerType = (prevState, newState) => {
		if (prevState.wallet_type != newState.wallet_type) {
			const newWalletType = walletTypes?.find((wt) => wt.code === newState.wallet_type);

			if (newWalletType?.payment_type === `MANUAL`) {
				setShowRequisites(true);
				fetchRequisites(newState.wallet_type || "");
			} else {
				setShowRequisites(false);
			}
		}
	};

	const formDataAPI = useFormDataAPI({
		initialState: initForm,
		onStateChange: handleFormChange,
	});

	const state = formDataAPI.formState;
	const { wallet_type: walletType, account_type, parser_type } = state;

	const prepareDataForSubmit = (form) => {
		let data = {...form};
		let cookie = JSON.parse(form.cookie || "{}");
		if (Object.keys(cookie).length === 0) {
			cookie = undefined;
		}
		const proxies = (data.proxies || []).map(({ uuid }: Partial<Proxy>) => uuid);

		const groups = ["email", "mobileData"];

		groups.forEach((part) => {
			if (data[part]) {
				data = {
					...data,
					...data[part],
				};
				delete data[part];
			}
		});

		//TODO: КОСТЫЛЬ, ПРИДУМАТЬ КАК СДЕЛАТЬ ИНАЧЕ
		if (data.type === WalletTypes.PHONE_PE && data.parser_type !== ParserType.PARSER_INDUSIN && "codephrases" in data) {
			delete data["codephrases"];
		}
		if (data.type === WalletTypes.PHONE_PE && data.parser_type !== ParserType.PARSER_HDFC && "parsing_object" in data) {
			delete data["parsing_object"];
		}

		return { ...data, proxies, cookie };
	};

	const handleSuccess: handleSuccessType = (form) => {
		onSubmit(
			prepareDataForSubmit(form)
		).then((resp) => {
			if (resp.status === "success") {
				onSuccess();
				closeModal && closeModal();
			}
		});
	};

	const change = formDataAPI.setValue;

	useEffect(() => {
		if (adminPanelType === "instance") {
			getProxiesAPI.load();
		}
	}, [getProxiesAPI.load]);

	useEffect(() => {
		if (walletType) {
			clearExternalErrors();

			if (adminPanelType === "instance") {
				getProxySettingsAPI.load(walletType);
			}

			formDataAPI.setTouchedFields([]);
		}
	}, [walletType]);

	useEffect(() => {
		for (const pair of Object.entries(initForm)) {
			const [key, value] = pair;
			change(key, value);
		}
	}, [initForm]);

	useEffect(() => {
		if (walletType === WalletTypes.BKASH) {
			if (account_type === AccountType.MERCHANT) {
				change(`parser_type`, ParserType.PARSER_SITE);
			} else if (account_type === AccountType.AGENT || account_type === AccountType.PERSONAL) {
				change(`parser_type`, ParserType.PARSER_EMAIL);
			}
		}
	}, [walletType, account_type]);

	useEffect(() => {
		if (parser_type && account_type && [WalletTypes.NAGAD, WalletTypes.BKASH].includes(walletType as WalletTypes)) {
			const possibleData = getWalletParserTypes(walletType || "", account_type) || [];
			const possibleValues = possibleData.map((data) => data.value);
			if (possibleValues.includes(parser_type as ParserType)) {
				change(`parser_type`, parser_type);
			} else {
				change(`parser_type`, possibleValues[0]);
			}
		}
	}, [walletType, account_type]);

	useEffect(() => {
		if (walletType === WalletTypes.NAGAD && account_type === AccountType.MERCHANT) {
			change(`parser_type`, ParserType.PARSER_SITE);
		}
	}, [walletType, account_type]);

	useEffect(() => {
		if (walletType === WalletTypes.PHONE_PE && isCreating) {
			change(`parser_type`, ParserType.PARSER_PHONE_PE_MOBILE_API);
		}
	}, [walletType, isCreating]);

	const proxyOptions = getProxiesAPI.list
		.map((proxy) => ({
			label: `${proxy.id} | ${proxy.country_code} | ${proxy.type} | ${proxy.ip}:${proxy.port} (${proxy.wallets.length})`,
			value: proxy.uuid,
			// Флаг послужит в useWalletData для стилизации лейбла
			isActive: proxy.status === 1,
		}));

	const fields = useWalletData(
		isCreating,
		handleSuccess,
		showRequisites ? requisites : [],
		!!getProxySettingsAPI.data?.use_proxy,
		proxyOptions,
		formDataAPI.formState,
		created_at,
		adminPanelType,
	) as any;

	if (loading || getProxiesAPI.isLoading) {
		return <>{t("Loading...")}</>;
	}

	return (
		<PayKitForm.Builder
			schema={fields} 
			formDataAPI={formDataAPI} 
			externalErrors={externalErrors}
		/>
	);
};

export default ChangeWalletModal;

export type handleSuccessType = (formState: FormStateType) => void;

export type FormStateType = {
	readonly account_type: AccountType;
	readonly wallet_type: string;
	readonly currency_code: string;
	readonly email_password: string;
	readonly email_username: string;
	readonly identifier: string;
	readonly parser_type: string;
	readonly priority: string;
	readonly proxies: readonly ProxyType[];
	readonly upi_addresses: readonly { readonly [key: string]: string }[];
	readonly status: string;
	readonly comment: string;
	readonly provider: unknown;
	readonly vendor_code: string;
	readonly wallet_off_limit: number;
	readonly wallet_off_load: number;
};
export type ProxyType = { readonly uuid: string };

// TODO: In case of enough free time all anys should be removed
type CreateWalletModalProps = ModalProps & {
	readonly isOpen: boolean;
	readonly onSubmit: (form: Partial<any>) => Promise<StandardResponse<any>>;
	readonly onSuccess: () => void;
	readonly initForm: Partial<any>;
	readonly loading: boolean;
	readonly isCreating: boolean;
	readonly externalErrors: any;
	readonly clearExternalErrors: () => void;
	readonly requisites: readonly Requisite[];
	readonly fetchRequisites: (wallet_type: string) => void;
	// Для кейса редактирования
	readonly created_at?: string;
	readonly adminPanelType: "instance" | "superadmin";
};

export type SelectOptionsType = {
	readonly label: string;
	readonly value: string;
	readonly isActive: boolean;
};

type FormChangeHandlerType = (prevState: Partial<FormStateType>, newState: Partial<FormStateType>) => void;
