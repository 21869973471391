export enum Roles {
	MANAGE_AUTO_GENERATING_REPORTS = `admin.reports.autoreports.manage`,
	PAYMENT_SETTINGS = `admin.payment.settings`,
	PLUGIN_SETTINGS = `admin.settings.payment-plugin`,
	WALLET_VISIBILITY = `admin.wallet.visibility`,
	WALLET_CREATE = `admin.wallet.create`,
	WALLET_UPDATE = `admin.wallet.update`,
	WALLET_DELETE = `admin.wallet.delete`,
	WALLET_HARD_DELETE = `admin.wallet.hard-delete`,
	WALLET_SET_ACTIVE = `admin.wallet.set-active`,
	PROXY_CREATE = `admin.proxy.create`,
	PROXY_UPDATE = `admin.proxy.update`,
	PROXY_DELETE = `admin.proxy.delete`,
	PROXY_CHECK = `admin.proxy.check`,
	RBAC = `admin.rbac.all`,
	TRANSACTION_LIST = `admin.transaction.list`,
	TRANSACTION_ACTIVATE = `transaction.activate`,
	SET_FAKE = `admin.transaction.set-fake`,
	TRANSACTION_ONLY_BY_ID = `admin.transaction.list-only-with-transaction-id`,
	TRANSACTION_MANAGEMENT = `admin.transaction.possible-transaction-manager`,
	TRANSACTION_DELETE = `admin.transaction.possible_transaction.delete`,
	PRELIMINARY_TRANSACTION = `admin.pretransaction.list`,
	PRELIMINARY_TRANSACTION_ONLY_BY_ID = `admin.pretransaction.list-only-with-pretransaction-id`,
	PRELIMINARY_TRANSACTION_MANAGEMENT = `admin.pretransaction.pretransaction-manager`,
	ARCHIVE_TRANSACTION_MANAGEMENT = `admin.transaction.archive-transaction-manager`,
	ARCHIVE_TRANSACTION_VIEW = `admin.transaction.archive-transactions-view`,
	DASHBOARD = `admin.dashboard.all`,
	CREATE_DEBUG_TRANSACTION = `admin.transaction.debug-transaction.create`,
	WALLET_LIST = `admin.wallet.list`,
	PROXY_LIST = `admin.proxy.list`,
	LOGS = `admin.logs.list`,
	WALLETS_LOAD = `admin.wallets-load`,
	WALLETS_DISABLED = `admin.disabled-wallets.list`,
	ARCHIVED_WALLETS_VIEW = 'archived-wallets.view',
	TRANSACTION_CLIENT = `admin.transaction.client`,
	PLUGIN_SETTINGS_CLIENT = `admin.settings.payment-plugin.client`,
	MANUAL_PS_MANAGEMENT = `admin.test_wallet_type.manager`,
	CREATE_FORCED_TRANSACTION = `admin.transaction.forced-transaction.create`,
	REMOVE_POSSIBLE_TRANSACTION = `admin.transaction.possible_transaction.delete`,
	MANAGE_PAYMENTS_CONSTRUCTOR = `admin.payment-systems.manage`,
	PAYTM_COOKIES_VIEW = `admin.paytm_cookies.view`,
	PAYTM_COOKIES_DELETE = `admin.paytm_cookies.delete`,
	PAYTM_COOKIES_UPLOAD = `admin.paytm_cookies.upload`,
	WALLET_BALANCE = `admin.wallet.balance-view`,
	WALLET_NUMBER = `admin.wallet.private-information-view`,
	MEDIA_SETTINGS = `admin.media.settings`,
	MANUAL_PS_LIST = `admin.test_payment_systems.list`,
	DISPLAY_TRANSACTION_TYPE = `admin.transaction.view-types`,
	TRANSACTION_REPORTS = `admin.report.transactions`,
	LABEL_HISTORY_REPORT = `admin.report.label-history`,
	TIMEZONE_SET = `admin.settings.timezone.update`,
	TIMEZONE_GET = `admin.settings.timezone.view`,
	POSTBACKS = `admin.postbacks.view`,
	WITHDRAWALS_VIEW = `admin.withdrawals.view`,
	WITHDRAWALS_MANAGE = `admin.withdrawals.manage`,
	WITHDRAWALS_CREATE = `admin.withdrawals.create`,
	TRANSACTION_INNER = `admin.transaction.inner`,
	TRANSACTION_NUMBER_VIEW = `admin.transaction.number.view`,
	STOCKPILING_LIST = `admin.stockpiling.list`,
	VIEW_CHANGE_LOG = `admin.change-log.view`,
	ACCOUNTS_BALANCE = `admin.account-balances.view`,
	VIEW_SETTLEMENT = `admin.account-balances.settlements.view`,
	SETTLEMENT_CREATE_WITHDRAWAL = `admin.account-balances.settlement-withdrawal.create`,
	MANAGER_BALANCE = `admin.account-balances.manage`,
	COMMISSION_REPORT = `admin.report.commission`,
	COMMISSION_VIEW = `admin.transaction.commission-view`,
	LOAD_TRANSACTIONS = `admin.transactions.load`,
	DELETE_PAYTM_COOKIES = `admin.paytm_cookies.delete`,
	WITHDRAWAL_REQUEST_REPORT = `admin.report.withdrawal-list`,
	SETTLEMENT_OPERATIONS_REPORT = `admin.report.settlement-list`,
	CLIENT_BALANCE_HISTORY_REPORT = `admin.reports.client-balance-history`,
	VIEW_SYSTEM_SETTINGS = `admin.settings.system-settings.view`,
	WALLET_PROVIDER_CODE = `admin.reports.wallet-provider-code-view`,
	TRANSACTION_SUSPECTS_TRANSACTIONS_VIEW = `admin.transaction.suspects_transactions_view`,
	TRANSACTION_SUSPECTS_APPROVE = `admin.transaction.suspects-transactions-approve`,
	TRANSACTION_PRIVATE_INFORMATION_VIEW = `admin.transaction.private-information.view`,
	PRIVATE_SETTINGS_VIEW = `private.settings.view`,
	WALLET_COMMENT = `wallet.comment`,
	WALLET_SETTINGS = `wallet.settings`,
	DEBUG_RULE = `debug.rule`,
	ACTIVATED_FAKE_DELETE = `activated-fake.delete`,
	POSTBACK_SENDING = `admin.postback.sending`,
}
